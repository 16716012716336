<template>
  <div>
    <edit-cart-invoice
      v-if="showEditInvoiceAddress"
      :showModal="showEditInvoiceAddress"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showEdit="true"
      :editFunction="setShowEditInvoiceAddress"
      :editLabel="$t('Components.Carts.CartDetail.Button_Edit')"
    >
      <template v-slot:title>Invoice address</template>
    </ui-section-header>

    <table class="table is-fullwidth is-borderless is-narrow">
      <tbody>
        <tr>
          <td>{{ $t('Components.Carts.CartDetail.Table_Attn') }}</td>
          <td>
            <span>{{ cart.InvoiceAddress.SendTo }}</span>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Carts.CartDetail.Table_Invoice-email') }}
          </td>
          <td>
            <span>{{ cart.InvoiceAddress.Email }}</span>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Carts.CartDetail.Table_PO-number') }}
          </td>
          <td>
            <span>{{ cart.InvoiceAddress.PoNumber }}</span>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Carts.CartDetail.Table_Address') }}
          </td>
          <td>
            <span>{{ cart.InvoiceAddress.Address }}</span>
          </td>
        </tr>
        <tr>
          <td>
            {{ $t('Components.Carts.CartDetail.Table_Postal-code') }}
          </td>
          <td>
            <span>{{ cart.InvoiceAddress.PostalCode }}</span>
          </td>
        </tr>
        <tr>
          <td>{{ $t('Components.Carts.CartDetail.Table_City') }}</td>
          <td>
            <span>{{ cart.InvoiceAddress.City }}</span>
          </td>
        </tr>
        <tr>
          <td>{{ $t('Components.Carts.CartDetail.Table_Phone') }}</td>
          <td>
            <span>{{ cart.InvoiceAddress.PhoneNumber }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const ModalEditInvoiceAddress = () =>
  import('@/components/Carts/ModalEditInvoiceAddress')

export default {
  components: {
    'edit-cart-invoice': ModalEditInvoiceAddress,
  },

  data() {
    return {
      showEditInvoiceAddress: false,
    }
  },
  computed: {
    ...mapState('cartStore', ['cart']),
  },

  created() {},

  methods: {
    setShowEditInvoiceAddress() {
      this.showEditInvoiceAddress = true
    },

    hideModal() {
      this.showEditInvoiceAddress = false
    },
  },
}
</script>

<style></style>
